<template>
    <div class="card card-height">
        <Teleport to="#add-new-note">
            <button
                @click="addNewNote"
                class="btn btn-primary waves-effect waves-float waves-light"
            >+ Add new
            </button>
        </Teleport>
        <div class="card-body">
            <div class="row">
                <div v-for="(note, index) in formattedTableItems" :key="index" class="col-12 note-section mb-2">
                    <RxNote
                        v-if="['investigation', 'rx'].includes(note.value)"
                        :note="note" :index="index"
                        @openModal="openModal"
                        :medicineNotes="prescriptionNotes.rx_notes"
                        from="group-template"
                        @onSave="handleSaveTemplate"
                    />
                    <AdmissionNote
                        :note="note" v-else
                        :index="index" 
                        @openModal="openModal"
                        from="group-template"
                        :noteTypes="prescriptionNotes.general_notes"
                        @onSave="handleSaveTemplate"
                    />
                </div>
            </div>
        </div>

        <p v-if="!formattedTableItems.length" class="text-center mt-5">No data</p>
        
        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
            noteType="group-template"
        />
        <AddRxNoteModal
            v-if="store.state.isModalOpenTwo"
            :note="singleNote"
            noteType="group-template"
        /> 
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import RxNote from '@/components/molecule/company/hospital/note/RxNote.vue';
import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
import AddRxNoteModal from '@/components/molecule/company/hospital/note/AddRxNoteModal.vue';
import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import upperFirst from 'lodash/upperFirst';

const store = useStore();
const route = useRoute();
const currentTab = computed(() => route.query.tab || 'rx');
const formattedTableItems = ref([]);
const singleNote = ref(null);

const props = defineProps({
    tableItems: {
        type: Array,
        default: () => []
    },
    prescriptionNotes: {
        type: Object,
        default: () => {}
    },
    tabs: {
        type: Array,
        default: () => []
    },
    onSaveTemplate: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onSaveTemplate']);

const handleSaveTemplate = () => {
    emit('onSaveTemplate');
}

const formatTableItems = () => {
    formattedTableItems.value = props.tableItems.map(note => {
        return getNewNote(
                    note.template_type, 
                    upperFirst(note.template_type), 
                    setSelectedValues(note.templates),
                    note.name,
                    note.id
                );
    })
}

const setSelectedValues = (oldItems) => {
    return oldItems.map(item => {
        return {
            template_id: item.id,
            templateable_id: item.templateable_id,
            name: item.templateable.name,
            price: 0,
            remarks: item.additional_info.remarks,
            isSelected: true,
            dose: item.additional_info.dose,
            unit: item.additional_info.unit,
            duration: item.additional_info.duration,
            madication_status: '',
            status: item.status,
            order_date: ''
        }
    })
}

const getNewNote = (value, title, selectedValues = [], templateName = '', id = null) => {
    return {
        id: id,
        name: title,
        value: value,
        template_name: templateName,
        isHideAddBtn: false,
        preAddedNotes: [],
        selectedNotes: selectedValues,
        newNotesByDoctor: [],
        noteMasters: []
    }
}

const getNoteByValue = (value, isLimit = true) => {
    const notes = props.prescriptionNotes.note_masters[value] || [];
    if(!notes.length) return [];

    if(isLimit){
        return notes.slice(0, 10);
    }

    return notes;
}

const openModal = ({note, index}) => {

    singleNote.value = null;
    
    singleNote.value = {
        index: index,
        name: note.name,
        value: note.value,
        selectedNotes: note.selectedNotes,
        preAddedNotes: note.value ? getNoteByValue(note.value) : [],
        noteMasters: note.value ? getNoteByValue(note.value, false) : [],
        newNotesByDoctor: note.newNotesByDoctor
    }

    if(['rx', 'investigation'].includes(note.value)){
        store.state.isModalOpenTwo = true;
        return;
    }
    
    store.state.isModalOpen = true;
}

const addNewNote = () => {
    if(currentTab.value === 'notes'){
        formattedTableItems.value.unshift(getNewNote('advice', 'Advice'));
        return;
    }
    const tab = props.tabs.find(tab => tab.value === currentTab.value);
    formattedTableItems.value.unshift(getNewNote(currentTab.value, (tab ? tab.name : '')));
}

watch(() => props.tableItems, (newValue) => {
    formatTableItems();
})

onMounted(() => {
    formatTableItems();
})
</script>

<style scoped>
.note-section {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
</style>