<template>
    <div class="profile-header-nav">
        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="profile-tabs d-flex justify-content-start flex-wrap mt-1 mt-md-0">
                    <ul class="nav nav-pills mb-0">
                        <li 
                            v-for="(tab, index) in tabs" :key="tab.value"
                            :tab="tab" :index="index"
                            class="nav-item"
                        >
                            <a 
                                href="javascript:void(0)" class="nav-link"
                                :class="activeTab === tab.value && 'fw-bold active'"
                                @click="onChangeTab(tab)"
                            >
                                <span class="d-md-block">{{ tab.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
    onTabChange: {
        type: Function,
        default: () => {}
    },
    tabs: {
        type: Array,
        default: () => []
    }
})

const emit = defineEmits(['onTabChange']);
const route = useRoute();
const router = useRouter();
const activeTab = ref('rx');

const onChangeTab = async (tab) => {

    activeTab.value = tab.value;

    const query = Object.assign({}, route.query);
    query.tab = tab.value;
    query.page = 1;

    await router.push({ 
        path: route.path, 
        query: query,
        params: route.params
    });

    emit('onTabChange', tab.value);
}

onMounted(() => {
    if(route.query.tab) {
        activeTab.value = route.query.tab;
    }
})
    
</script>

<style scoped>
.nav-link {
    min-width: 80px !important;
}
.nav-link{
    padding: 8px 6px;
}
.navbar {
    padding-right: 1%;
}
.nav-link {
    border: 1px solid #7367f0;
    margin-left: 5px;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 990px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .nav-pills .nav-link {
      padding: 0.786rem 0.5rem !important;
  }
}

@media (min-width: 768px){
    .profile-header-nav .profile-tabs {
        margin-left: 0 !important;
    }
}
</style>